<template>
  <div>
    <ManageEurojackpotOrder />
  </div>
</template>
<script>
import ManageEurojackpotOrder from "@/components/Order/ManageEurojackpotOrder";
export default {
  components: {
    ManageEurojackpotOrder,
  },
  created() {
  },
};
</script>